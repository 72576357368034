import React, { useState } from "react"
import Logo from "../images/bookdood-logo.svg"
import { Link } from "gatsby"
import { AuthProvider, AuthConsumer } from "./utils/AuthContext"

const Navbar = () => {
  const [burger, setBurger] = useState(false)

  return (
    <AuthProvider>
      <AuthConsumer>
        {({ isAuth, logout, user }) => (
          <nav
            className="navbar"
            role="navigation"
            aria-label="main navigation"
          >
            <div className="container">
              <div className="navbar-brand">
                <Link className="navbar-item" to="/">
                  <img src={Logo} width="auto" height="24" alt="GrowKarma" style={{height: "18px"}} />
                </Link>

                <a
                  role="button"
                  className={
                    burger
                      ? "navbar-burger burger is-active"
                      : "navbar-burger burger"
                  }
                  onClick={() => setBurger(!burger)}
                >
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                </a>
              </div>

              <div className={burger ? "navbar-menu is-active" : "navbar-menu"}>
                <div className="navbar-end">
                  <Link to="/browse" className="navbar-item">
                    Browse catalog
                  </Link>
                  <a
                    href="https://app.bookdood.com/login/signin"
                    target="_blank"
                    className="navbar-item"
                  >
                    Login
                  </a>
                </div>
              </div>
            </div>
          </nav>
        )}
      </AuthConsumer>
    </AuthProvider>
  )
}

export default Navbar
